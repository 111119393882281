import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/layouts/error/error.component';


import { WebTermsComponent } from './components/appviews/multipage/web-terms/web-terms.component';
import { ServiceTermsComponent } from './components/appviews/multipage/service-terms/service-terms.component';
import { PrivacyTermsComponent } from './components/appviews/multipage/privacy-terms/privacy-terms.component';
import { FAQComponent } from './components/appviews/multipage/faq/faq.component';
import { ContactComponent } from './components/appviews/multipage/contact/contact.component';
import { HomeComponent } from './components/appviews/home/home.component';
import { Step1VisaTypeComponent } from './components/appviews/applicationForm/step1-visa-type/step1-visa-type.component';
import { Step2BasicDetailsComponent } from './components/appviews/applicationForm/step2-basic-details/step2-basic-details.component';
import { Step3PersonalDetailsComponent } from './components/appviews/applicationForm/step3-personal-details/step3-personal-details.component';
import { Step4AttachmentComponent } from './components/appviews/applicationForm/step4-attachment/step4-attachment.component';
import { Step5ReviewComponent } from './components/appviews/applicationForm/step5-review/step5-review.component';
import { Step6PaymentComponent } from './components/appviews/applicationForm/step6-payment/step6-payment.component';
import { AboutUsComponent } from './components/appviews/multipage/about-us/about-us.component';
import { ServicesComponent } from './components/appviews/multipage/services/services.component';
import { PaymentResultComponent } from './components/appviews/applicationForm/payment-result/payment-result.component';
import { TrackingComponent } from './components/appviews/tracking/tracking/tracking.component';
import { TrackingResultComponent } from './components/appviews/tracking/tracking-result/tracking-result.component';
import { NewAgentRequestComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request.component';
import { NewAgentRequestAttachmentComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request-attachment/new-agent-request-attachment.component';
import { ReferBackAttachmentComponent } from './components/appviews/tracking/refer-back-attachment/refer-back-attachment.component';
import { DownloadVisaComponent } from './components/appviews/tracking/download-visa/download-visa.component';
import { RequestResultComponent } from './components/appviews/applicationForm/new-agent-request/request-result/request-result.component';
import { ApplicationStatusComponent } from './components/appviews/multipage/application-status/application-status.component';


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', pathMatch: 'full', component: HomeComponent },
    { path: 'contact', pathMatch: 'full', component: ContactComponent },
    { path: 'service-terms', pathMatch: 'full', component: ServiceTermsComponent },
    { path: 'privacy-terms', pathMatch: 'full', component: PrivacyTermsComponent },
    { path: 'web-terms', pathMatch: 'full', component: WebTermsComponent },
    { path: 'faq', pathMatch: 'full', component: FAQComponent },
    { path: 'step1', pathMatch: 'full', component: Step1VisaTypeComponent },
    { path: 'step2', pathMatch: 'full', component: Step2BasicDetailsComponent },
    { path: 'step3', pathMatch: 'full', component: Step3PersonalDetailsComponent },
    { path: 'step4', pathMatch: 'full', component: Step4AttachmentComponent },
    { path: 'step5', pathMatch: 'full', component: Step5ReviewComponent },
    { path: 'step6', pathMatch: 'full', component: Step6PaymentComponent },
    { path: 'about-us', pathMatch: 'full', component: AboutUsComponent },
    { path: 'application-status', pathMatch: 'full', component: ApplicationStatusComponent },
    { path: 'services', pathMatch: 'full', component: ServicesComponent },
    { path: 'payment-result', pathMatch: 'full', component: PaymentResultComponent },
    { path: 'tracking', pathMatch: 'full', component: TrackingComponent },
    { path: 'tracking-result', pathMatch: 'full', component: TrackingResultComponent },
    { path: 'new-agent-request', pathMatch: 'full', component: NewAgentRequestComponent },
    { path: 'request-result', pathMatch: 'full', component: RequestResultComponent },
    { path: 'new-agent-request-attachment', pathMatch: 'full', component: NewAgentRequestAttachmentComponent },
    { path: 'refer-back-attachment', pathMatch: 'full', component: ReferBackAttachmentComponent },
    { path: 'download-visa', pathMatch: 'full', component: DownloadVisaComponent },

	{path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)
    ],
	exports: [RouterModule]
})
export class AppRoutingModule {}
